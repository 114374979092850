import React from 'react';
import './About.css';

const CustomBanner = () => {
  return (
    <div className="container-fluid bg-primary p-5">
      <div className="row">
                <div className="col-12 text-center">
                    <h1 className="text-uppercase text-white text-animate">SOBRE</h1>
                    <hr className="custom-hr text-animate" />
                    <p className="lead text-white text-animate">Com mais de 2.000 piscinas em todo o país, vamos atender prontamente todas as suas expectativas com o profissionalismo que você merece</p>
                </div>
            </div>
    </div>
  );
};

export default CustomBanner;
//007BFF