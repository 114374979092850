import React from 'react';
import Header from '../Header';
import Footer from "../Footer";
import CustomBanner from "./CustomBanner";
import AboutUs from "./AboutUs";
import FAQComponent from "./FAQComponent";
import AboutServicesGrid from "./AboutServicesGrid";
import './About.css';

function Home() {
    return (
        <div>
            <Header/>
            <CustomBanner/>
            <AboutServicesGrid/>
            <AboutUs/>
            <FAQComponent/>
            <Footer/>
        </div>
    );
}

export default Home;
