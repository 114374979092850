import React from "react";
import Header from "../Header";
import VacumCleanerBanner from "./VacumCleanerBanner";
import Footer from "../Footer";
import VacumCleanerLayer1 from "./VacumCleanerLayer1";
import VacumCleanerLayer2 from "./VacumCleanerLayer2";
import VacumCleanerLayer3 from "./VacumCleanerLayer3";
import VacumCleanerLayer4 from "./VacumCleanerLayer4";
import VacumCleanerLayer5 from "./VacumCleanerLayer5";
import VacumCleanerLayer6 from "./VacumCleanerLayer6";

import "./VacumCleaner";

function VacumCleaner() {
  return (
    <div>
      <Header />
      <VacumCleanerBanner />
      <VacumCleanerLayer1 />
      <VacumCleanerLayer2 />
      <VacumCleanerLayer3 />
      <VacumCleanerLayer6 />
      <VacumCleanerLayer5 />
      <Footer />
    </div>
  );
}

export default VacumCleaner;
