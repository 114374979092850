import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./VacumCleaner.css"; // Ensure the CSS file is linked properly
import VacumCleanerTigerShark from "../../assets/VacumCleaners/VacumCleanerTigerShark.png"; // Adjust the path as necessary
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot, faTrash, faWater } from "@fortawesome/free-solid-svg-icons";

const VacumCleanerLayer6 = () => {
  const isSmallScreen = window.innerWidth <= 768;

  return (
    <div className="container-fluid automatic-covers blue-background">
      <div className="row">
        {isSmallScreen ? (
          <>
            <div className="col-md-6 order-1 order-md-2 d-flex flex-column align-items-center">
              <div className="image-container">
                <img src={VacumCleanerTigerShark} alt="TigerShark Vacuum Cleaner" className="cover-img-vacum" />
              </div>
            </div>
            <div className="col-md-6 order-2 order-md-1 text-section">
              <h2 className="text-white">Tiger Shark - HAYWARD©</h2>
              <p className="text-white">
                O Tiger Shark tem computador integrado inteligente que calcula o tamanho da piscina e o trajeto de limpeza mais eficiente.{" "}
              </p>
              <p className="text-white">Ciclo de limpeza de 4 horas pré-programado que se desliga automaticamente quando termina.</p>
              <div className="row icon-features text-center mt-5">
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faRobot} size="3x" className="fa-icon custom-icon-color" />
                  <p>Inteligente</p>
                </div>
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faTrash} size="3x" className="fa-icon custom-icon-color" />
                  <p>Alta Capacidade</p>
                </div>
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faWater} size="3x" className="fa-icon custom-icon-color" />
                  <p>Limpeza Profunda</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-md-6 text-section">
              <h2 className="text-white">Tiger Shark - HAYWARD©</h2>
              <p className="text-white">
                O Tiger Shark tem computador integrado inteligente que calcula o tamanho da piscina e o trajeto de limpeza mais eficiente.{" "}
              </p>
              <p className="text-white">Ciclo de limpeza de 4 horas pré-programado que se desliga automaticamente quando termina.</p>
              <div className="row icon-features text-center mt-5">
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faRobot} size="3x" className="fa-icon custom-icon-color" />
                  <p>Inteligente</p>
                </div>
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faTrash} size="3x" className="fa-icon custom-icon-color" />
                  <p>Alta Capacidade</p>
                </div>
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faWater} size="3x" className="fa-icon custom-icon-color" />
                  <p>Limpeza Profunda</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex flex-column align-items-center">
              <div className="image-container">
                <img src={VacumCleanerTigerShark} alt="TigerShark Vacuum Cleaner" className="cover-img-vacum" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VacumCleanerLayer6;
