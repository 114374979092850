import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Home.css";
import Retangular from "../../assets/BackgroundWithCards/retangular.webp";
import Topo from "../../assets/BackgroundWithCards/EscadasTopo.webp";
import Jacuzzi from "../../assets/BackgroundWithCards/jacuzzi.webp";
import Infinita from "../../assets/BackgroundWithCards/infinita.webp";
import Canto from "../../assets/BackgroundWithCards/canto.webp";
import Praia from "../../assets/BackgroundWithCards/praia.webp";

/*
 {
        title: "ROMANA",
        description: "Perfeito design, solução mais clássica, conforto garantido",
        buttonText: "SOLICITAR ORÇAMENTO",
        imgUrl: Romana
    },
    
    */
const cards = [
  {
    title: "RETANGULAR",
    description: "O clássico das piscinas, perfeita na relação qualidade/preço.",
    buttonText: "SOLICITAR ORÇAMENTO",
    imgUrl: Retangular,
  },
  {
    title: "TOPO",
    description: "Um clássico adaptado, um conforto extra.",
    buttonText: "SOLICITAR ORÇAMENTO",
    imgUrl: Topo,
  },
  {
    title: "JACUZZI",
    description: "Dois Espaços num só, relaxar é o mood.",
    buttonText: "SOLICITAR ORÇAMENTO",
    imgUrl: Jacuzzi,
  },
  {
    title: "INFINITA",
    description: "Perfeita para espaços com desníveis, digna das melhores paisagens.",
    buttonText: "SOLICITAR ORÇAMENTO",
    imgUrl: Infinita,
  },
  {
    title: "CANTO",
    description: "Perfeita para espaços mais pequenos e para amantes de natação.",
    buttonText: "SOLICITAR ORÇAMENTO",
    imgUrl: Canto,
  },
  {
    title: "PRAIA",
    description: "Ideal para famílias com crianças.",
    buttonText: "SOLICITAR ORÇAMENTO",
    imgUrl: Praia,
  },
];

const BackgroundWithCards = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const next = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length);
  };

  const prev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
  };

  const getVisibleCards = () => {
    return [
      cards[currentIndex],
      cards[(currentIndex + 1) % cards.length],
      cards[(currentIndex + 2) % cards.length],
      cards[(currentIndex + 3) % cards.length],
    ];
  };

  return (
    <div>
      <div className="container-fluid background-container text-white" style={{ "margin-top": "24px" }}>
        <div className="container p-1">
          <button className="carousel-button prev" onClick={prev}>
            ‹
          </button>
          <div className="row justify-content-center">
            {getVisibleCards().map((card, index) => (
              <div className="col-md-6 mb-4 display-card" key={index}>
                <div className="card text-center h-100 service-item d-flex flex-column">
                  <div className="row no-gutters">
                    <div className="col-md-6">
                      <img src={card.imgUrl} className="card-img-top" alt={card.title} />
                    </div>
                    <div className="col-md-6 d-flex flex-column">
                      <div className="card-body flex-grow-1 d-flex flex-column justify-content-center">
                        <h3 className="card-title">{card.title}</h3>
                        <p className="card-text">{card.description}</p>
                      </div>
                      <div className="d-flex justify-content-center">
                        <a className="button-a-orcamento" href="/Contacto">
                          <button className="btn btn-custom-home mt-auto">{card.buttonText}</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button className="carousel-button next" onClick={next}>
            ›
          </button>
        </div>
      </div>
    </div>
  );
};

export default BackgroundWithCards;
