import React from 'react';
import './Home.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faWrench, faSwimmer, faTree } from '@fortawesome/free-solid-svg-icons';

const ServicesGrid = () => {
    return (
            <div className="container-fluid bg-primary pt-2">
                <div className="container">
                <div className="services-grid">
                    <div className="grid-item service-item">
                        <FontAwesomeIcon icon={faShoppingCart} size="3x" className="fa-icon custom-icon-color" />
                        <h4>Venda de Produtos</h4>
                        <p>Temos todos os equipamentos e produtos que necessita para manter a sua piscina em dia.</p>
                    </div>
                    <div className="grid-item service-item">
                        <FontAwesomeIcon icon={faWrench} size="3x" className="fa-icon custom-icon-color" />
                        <h4>Assistência Técnica</h4>
                        <p>Temos um serviço de assistência técnica.</p>
                    </div>
                    <div className="grid-item service-item">
                        <FontAwesomeIcon icon={faSwimmer} size="3x" className="fa-icon custom-icon-color" />
                        <h4>Construção de Piscinas</h4>
                        <p>Oferecemos serviços de construção de piscinas com alta qualidade e durabilidade.</p>
                    </div>
                    <div className="grid-item service-item">
                        <FontAwesomeIcon icon={faTree} size="3x" className="fa-icon custom-icon-color" />
                        <h4>Espaços Verdes</h4>
                        <p>Criamos e mantemos espaços verdes para embelezar o seu ambiente.</p>
                    </div>
                </div>
                </div>
            </div>
    );
}

export default ServicesGrid;
