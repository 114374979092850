import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Services from "./components/Services/Maintenance";
import Covers from "./components/Covers/Covers"
import VacumCleaner from "./components/VacumCleaner/VacumCleaner"
import EquipmentProducts from "./components/EquipmentProducts/EquipmentProducts"
import GreenAreas from "./components/GreenAreas/GreenAreas"
import Contact from "./components/Contact/Contact"
import BeforeAfter from "./components/BeforeAfter/BeforeAfter"
import Portfolio from "./components/Portfolio/Portfolio"
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sobre" element={<About />} />
        <Route path="/Servicos" element={<Services />} />
        <Route path="/Espaços-Verdes" element={<GreenAreas />} />
        <Route path="/Contacto" element={<Contact />} />
        <Route path="/Antes-e-Depois" element={<BeforeAfter />} />
        <Route path="/Portfolio" element={<Portfolio />} />
        <Route path="/Coberturas" element={<Covers />} />
        <Route path="/Aspiradores" element={<VacumCleaner />} />
        <Route path="/Equipamento-produtos" element={<EquipmentProducts />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
