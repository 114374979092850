import React from 'react';
import Header from '../Header';
import EquipmentProductsBanner from './EquipmentProductsBanner';
import Footer from "../Footer"
import EquipmentProductsLayer1 from "./EquipmentProductsLayer1"

import './EquipmentProducts';

function EquipmentProducts() {
    return (
        <div>
            <Header/>
            <EquipmentProductsBanner/>
            <EquipmentProductsLayer1/>
            <Footer/>
        </div>
    );
}

export default EquipmentProducts;