import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./VacumCleaner.css"; // Make sure this CSS file is available and properly linked
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot, faSwimmingPool, faSearch } from "@fortawesome/free-solid-svg-icons";
import VacumCleaner20XATYPE from "../../assets/VacumCleaners/VacumCleaner20XATYPE.png"; // Adjust the path as necessary

const VacumCleanerLayer1 = () => {
  return (
    <div className="container-fluid automatic-covers">
      <div className="row">
        <div className="col-md-6 d-flex flex-column align-items-center">
          <div className="image-container">
            <img src={VacumCleaner20XATYPE} alt="Vacuum Cleaner 20XATYPE" className="cover-img-vacum" />
          </div>
        </div>
        <div className="col-md-6 text-section">
          <h2>20 XA TYPE - ZODIAC©</h2>
          <p>
            O 20 XA TYPE tem os seus sensores inteligentes incorporados e a sua conceção exclusiva, o 20 XA TYPE™ oferece uma excelente cobertura da
            piscina e uma agilidade sem limites. Desta forma, funciona idealmente em todas as formas de piscina e todos os tipos de revestimento.
          </p>
          <div className="row icon-features text-center mt-5">
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faRobot} size="3x" className="fa-icon custom-icon-color" />
              <p>Automático</p>
            </div>
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faSwimmingPool} size="3x" className="fa-icon custom-icon-color" />
              <p>Multi-Superfície</p>
            </div>
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faSearch} size="3x" className="fa-icon custom-icon-color" />
              <p>Navegação Inteligente</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VacumCleanerLayer1;
