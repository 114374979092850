import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import AboutUs1 from "../../assets/About/AboutUs/AboutUs1.jpg"
import AboutUs2 from "../../assets/About/AboutUs/AboutUs2.jpg"
import AboutUs3 from "../../assets/About/AboutUs/AboutUs3.jpg"
import './About.css';

// Define the array of image URLs (assuming these are placed correctly in your public folder or hosted online)
const images = [
    AboutUs1,
    AboutUs2,
    AboutUs3
];

const AboutUs = () => {
  return (
    <div className="container my-5">
      <div className="row align-items-center">
        <div className="col-md-6 animate-text-about" style={{ padding: '20px' }}>
          <h2>Sobre Nós</h2>
          <p style={{ fontSize: '16px', lineHeight: '1.5' }}>
            <b>HIDRO LAZER</b> é uma empresa dedicada a ESPAÇOS AQUÁTICOS, ESPAÇOS VERDES, ZONAS DE RECREIO E LAZER. 
          </p>
          <p style={{ fontSize: '16px', lineHeight: '1.5' }}>
            Com mais de 20 anos de experiência, continuamos a progredir no sentido de criar projetos
            com soluções inovadoras e duráveis, acompanhando a velocidade dos novos tempos.
          </p>
          <p style={{ fontSize: '16px', lineHeight: '1.5' }}>
          Uma equipa com profissionais empenhados e dedicados com o objectivo final de obter a 
          sua satisfação máxima. Propomos-lhe soluções capazes de tornar os seus projectos e 
          sonhos em realidade. 
          </p>
          <p style={{ fontSize: '16px', lineHeight: '1.5' }}>
          Sendo uma piscina um bem valioso que requer conhecimento e experiência, deixe a sua
           construção nas mãos de profissionais competentes. Venha visitar-nos. 
          </p>
          </div>
        <div className="col-md-6 animate-carousel-about">
          <Carousel className="carousel-item-about">
            {images.map((img, index) => (
              <Carousel.Item  key={index}>
                <img
                  className="d-block w-100"
                  src={img}
                  alt={`Slide ${index + 1}`}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
