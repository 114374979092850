// src/components/QuoteForm.js
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "./Home.css";

function QuoteForm() {
  return (
    <div className="quote-form-container">
      <div className="button-3dModel">
        <button className="my-button-3dModel">MODELOS 3D</button>
      </div>

      <div className="quote-form-box">
        <div className="top-line"></div> {/* This adds the colored top line */}
        <h2 className="quote-form-header">Receba um Orçamento</h2>
        <form>
          <div className="mb-3">
            <input type="text" className="form-control" placeholder="Nome" />
          </div>
          <div className="mb-3">
            <input type="email" className="form-control" placeholder="E-mail" />
          </div>
          <div className="mb-3">
            <input type="tel" className="form-control" placeholder="Telefone" />
          </div>
          <div className="mb-3">
            <input type="morada" className="form-control" placeholder="Morada" />
          </div>
          <div className="mb-3">
            <textarea className="form-control" rows="4" placeholder="Texto"></textarea>
          </div>
          <button type="submit" className="btn btn-primary quote-form-button">
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
}

export default QuoteForm;
