import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AutomaticCovers.css'; // Make sure this CSS file is available and properly linked
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faThermometerHalf, faWrench } from '@fortawesome/free-solid-svg-icons';
import AutomaticCoversLayer1_1 from "../../assets/Covers/AutomaticCoversLayer1_1.webp";
import AutomaticCoversLayer1_3 from "../../assets/Covers/AutomaticCoversLayer1_3.webp";

const AutomaticCoversLayer1 = () => {
  return (
    <div className="container-fluid automatic-covers">
      <div className="row">
        <div className="col-md-6 d-flex flex-column align-items-center align-self-center">
          <div className="image-container">
            <img src={AutomaticCoversLayer1_1} alt="Cover 1" className="cover-img" />
            <img src={AutomaticCoversLayer1_3} alt="Cover 3" className="cover-img" />
          </div>
        </div>
        <div className="col-md-6 text-section">
          <h2>Coberturas Automáticas</h2>
          <p>Material em PVC, idêntico aos estores, estrutura automática que funciona com fecho central. Fácil utilização.</p>
          <div className="row icon-features text-center mt-5">
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faLock} size="3x" className="fa-icon custom-icon-color" />
              <p>+ Segurança</p>
            </div>
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faThermometerHalf} size="3x" className="fa-icon custom-icon-color" />
              <p>+ Temperatura</p>
            </div>
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faWrench} size="3x" className="fa-icon custom-icon-color" />
              <p>- Manutenção</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AutomaticCoversLayer1;
