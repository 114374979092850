import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import logoWhite from '../assets/Home/logo_branco_s_fundo.webp'; // Import the white logo


const Footer = () => {
    return (
        <div className="footer d-flex align-items-center">
            <div className="footer-column">
                <img src={logoWhite} alt="Logo" className="logo logo-white-footer" />
                <div>
                <a href="https://www.facebook.com/hidrolazer.piscinas.1" target="_blank" className="social-icon"><FontAwesomeIcon icon={faFacebookF} /></a>
                <a href="https://www.instagram.com/hidrolazer_/" target="_blank" className="social-icon"><FontAwesomeIcon icon={faInstagram} /></a>
                <a href="mailto:geral@hidro-lazer.com" className="social-icon"><FontAwesomeIcon icon={faEnvelope} /></a>
                </div>
            </div>
            <div className="footer-column">
                <a href="https://www.google.com/maps/search/?api=1&query=Rua+Serpa+Pinto+49+A/r/c+Cartaxo" target="_blank" rel="noopener noreferrer" className="social-icon">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                </a>
                <p><a href="https://www.google.com/maps/search/?api=1&query=Rua+Serpa+Pinto+49+A/r/c+Cartaxo" target="_blank" rel="noopener noreferrer">Rua Serpa Pinto, 49 A/r/c</a></p>
                <p>Cartaxo</p>
            </div>
            <div className="footer-column">
                <a href="tel:+351243719232" className="social-icon">
                    <FontAwesomeIcon icon={faPhone} />
                </a>
                <p><a href="tel:+351243719232">Tel: +351 243 719 232</a></p>
                <p><a href="tel:+351916421075">Telm: +351 916 421 075</a></p>
            </div>
            <div className="footer-column">
                <a href="mailto:geral@hidro-lazer.com" className="social-icon">
                    <FontAwesomeIcon icon={faEnvelope} />
                </a>
                <p><a href="mailto:geral@hidro-lazer.com">geral@hidro-lazer.com</a></p>
            </div>
        </div>
    );
}

export default Footer;
