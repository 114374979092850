import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AutomaticCovers.css';  // Ensure the CSS file is linked properly
import AutomaticCoversLayer2_2 from "../../assets/Covers/AutomaticCoversLayer2_2.webp";
import AutomaticCoversLayer2_3 from "../../assets/Covers/AutomaticCoversLayer2_3.webp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faTemperatureHigh, faRecycle } from '@fortawesome/free-solid-svg-icons';

const AutomaticCoversLayer2 = () => {
  const isSmallScreen = window.innerWidth <= 768;

  return (
    <div className="container-fluid automatic-covers blue-background">
      <div className="row">
        {isSmallScreen ? (
          <>
            <div className="col-md-6 order-1 order-md-2 d-flex flex-column align-items-center align-self-center">
              <div className="image-container">
                <img src={AutomaticCoversLayer2_2} alt="Cover 2" className="cover-img" />
                <img src={AutomaticCoversLayer2_3} alt="Cover 3" className="cover-img" />
              </div>
            </div>
            <div className="col-md-6 order-2 order-md-1 text-section">
              <h2 className="text-white">Coberturas Telescópicas</h2>
              <p className="text-white">Estrutura individual com módulos, fixos ao solo, com a área que desejar, permite a contínua utilização da piscina.</p>
              <div className="row icon-features text-center mt-5">
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faLock} size="3x" className="fa-icon custom-icon-color" />
                  <p>+ Segurança</p>
                </div>
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faTemperatureHigh} size="3x" className="fa-icon custom-icon-color" />
                  <p>+ Temperatura</p>
                </div>
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faRecycle} size="3x" className="fa-icon custom-icon-color" />
                  <p>- Manutenção</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-md-6 text-section">
              <h2 className="text-white">Coberturas Telescópicas</h2>
              <p className="text-white">Estrutura individual com módulos, fixos ao solo, com a área que desejar, permite a contínua utilização da piscina.</p>
              <div className="row icon-features text-center mt-5">
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faLock} size="3x" className="fa-icon custom-icon-color" />
                  <p>+ Segurança</p>
                </div>
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faTemperatureHigh} size="3x" className="fa-icon custom-icon-color" />
                  <p>+ Temperatura</p>
                </div>
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faRecycle} size="3x" className="fa-icon custom-icon-color" />
                  <p>- Manutenção</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex flex-column align-items-center align-self-center">
              <div className="image-container">
                <img src={AutomaticCoversLayer2_2} alt="Cover 2" className="cover-img" />
                <img src={AutomaticCoversLayer2_3} alt="Cover 3" className="cover-img" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AutomaticCoversLayer2;
