import React, { useState } from "react";
import Header from "../Header";
import BeforeAfterBanner from "./BeforeAfterBanner";
import BeforeAfterSlider from "./BeforeAfterSlider";
import VideoDisplayLayer from "./VideoDisplayLayer";
import Footer from "../Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BeforeAfter.css"; // Ensure this CSS file is linked properly

function BeforeAfter() {
  const [view, setView] = useState("comparison");

  const handleViewChange = (view) => {
    setView(view);
  };

  return (
    <div>
      <Header />
      <BeforeAfterBanner />
      <div className="container my-4 text-center container-beforeAfter">
        <div className="row justify-content-center ml-5">
          <button className={`btn btn-custom  ${view === "comparison" ? "btn-custom-selected" : ""}`} onClick={() => handleViewChange("comparison")}>
            Antes e Depois Fotos
          </button>
          <button className={`btn btn-custom ${view === "videos" ? "btn-custom-selected" : ""}`} onClick={() => handleViewChange("videos")}>
            Antes e Depois Vídeos
          </button>
        </div>
      </div>
      {view === "comparison" ? <BeforeAfterSlider /> : <VideoDisplayLayer />}
      <Footer />
    </div>
  );
}

export default BeforeAfter;
