import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./About.css";

const FAQComponent = () => {
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-12 main-title">
          <h2>Perguntas frequentes</h2>
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 faq-entry">
          <h4>Em que área prestamos serviço?</h4>
          <p>Prestamos serviços na região centro, Santarém, Lisboa e arredores.</p>
        </div>
        <div className="col-md-6 faq-entry">
          <h4>Qual é o horário de funcionamento?</h4>
          <p>Segunda a Sexta das 9h00 – 13h00 / 15h00 – 19h00 e Sábado das 9h00 - 13h00</p>
        </div>
        <div className="col-md-6 faq-entry">
          <h4>Quais os serviços que prestamos?</h4>
          <p>Serviços no ramo da construção civil, piscinas e anexos.</p>
        </div>
        <div className="col-md-6 faq-entry">
          <h4>Como são feitas as nossas piscinas?</h4>
          <p>Todas as nossas piscinas são construídas no local.</p>
        </div>
      </div>
    </div>
  );
};

export default FAQComponent;
