import React from 'react';
import Header from '../Header';
import Footer from "../Footer"
import PortfolioBanner from "./PortfolioBanner"
import ImageGrid from "./ImageGrid"
import './Portfolio';

function Portfolio() {
    return (
        <div>
            <Header/>
            <PortfolioBanner/>
            <ImageGrid/>
            <Footer/>
        </div>
    );
}

export default Portfolio;