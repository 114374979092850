import React from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import landscapeImageArquitectura1 from "../../assets/GreenAreas/LandscapeArchitecture/ArquiteturaPaisagista (1).jpg";
import landscapeImageArquitectura2 from "../../assets/GreenAreas/LandscapeArchitecture/ArquiteturaPaisagista (2).jpg";
import landscapeImageArquitectura3 from "../../assets/GreenAreas/LandscapeArchitecture/ArquiteturaPaisagista (3).jpg";
import landscapeImageArquitectura4 from "../../assets/GreenAreas/LandscapeArchitecture/ArquiteturaPaisagista (4).jpg";
import landscapeImageArquitectura5 from "../../assets/GreenAreas/LandscapeArchitecture/ArquiteturaPaisagista (5).jpg";
import landscapeImageArquitectura6 from "../../assets/GreenAreas/LandscapeArchitecture/ArquiteturaPaisagista (6).jpg";
import landscapeImageJardins1 from "../../assets/GreenAreas/LandscapeArchitecture/Construção Jardins (1).jpeg";
import landscapeImageJardins2 from "../../assets/GreenAreas/LandscapeArchitecture/Construção Jardins (1).jpg";
import landscapeImageJardins3 from "../../assets/GreenAreas/LandscapeArchitecture/Construção Jardins (2).jpg";
import landscapeImageJardins4 from "../../assets/GreenAreas/LandscapeArchitecture/Construção Jardins (3).jpg";
import landscapeImageJardins5 from "../../assets/GreenAreas/LandscapeArchitecture/Construção Jardins (4).jpg";
import landscapeImageJardins6 from "../../assets/GreenAreas/LandscapeArchitecture/Construção Jardins (5).jpg";
import "./greenAreas.css";

const landscapeImagesArquitectura = [
  landscapeImageArquitectura1,
  landscapeImageArquitectura2,
  landscapeImageArquitectura3,
  landscapeImageArquitectura4,
  landscapeImageArquitectura5,
  landscapeImageArquitectura6,
];

const landscapeImagesJardins = [
  landscapeImageJardins1,
  landscapeImageJardins2,
  landscapeImageJardins3,
  landscapeImageJardins4,
  landscapeImageJardins5,
  landscapeImageJardins6,
];

const LandscapeArchitecture = () => {
  const isSmallScreen = window.innerWidth <= 768;

  return (
    <div className="landscape-architecture">
      <div className="container-fluid bg-primary p-5">
        <div className="row align-items-center d-flex justify-content-center">
          {isSmallScreen ? (
            <>
              <div className="col-md-6 order-1 order-md-2">
                <Carousel className="carousel-item-about">
                  {landscapeImagesArquitectura.map((img, index) => (
                    <Carousel.Item key={index}>
                      <img className="d-block w-100" src={img} alt={`Slide ${index + 1}`} />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
              <div className="col-md-5 order-2 order-md-1 p-4 text-center text-column">
                <h2 className="mb-5 text-white">Arquitectura Paisagista</h2>
                <p className="text-white">
                  A Arquitectura Paisagista é uma arte, é uma ciência que tem como objecto final a Paisagem. Na Hidro Lazer, temos o prazer de contar
                  com profissionais que estudam e compreendem os sistemas, pressupondo ordenar, criar e construir o espaço em relação ao homem.
                </p>
                <a href="/Contacto">
                  <button className="btn btn-custom-landscape mt-auto my-button-landscape">Saber mais</button>
                </a>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-5 p-5 text-center text-column">
                <h2 className="mb-5 text-white">Arquitectura Paisagista</h2>
                <p className="text-white">
                  A Arquitectura Paisagista é uma arte, é uma ciência que tem como objecto final a Paisagem. Na Hidro Lazer, temos o prazer de contar
                  com profissionais que estudam e compreendem os sistemas, pressupondo ordenar, criar e construir o espaço em relação ao homem.
                </p>
                <a href="/Contacto">
                  <button className="btn btn-custom-landscape mt-auto my-button-landscape">Saber mais</button>
                </a>
              </div>
              <div className="col-md-6">
                <Carousel className="carousel-item-about">
                  {landscapeImagesArquitectura.map((img, index) => (
                    <Carousel.Item key={index}>
                      <img className="d-block w-100" src={img} alt={`Slide ${index + 1}`} />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="container-fluid p-5">
        <div className="row align-items-center d-flex justify-content-center">
          {isSmallScreen ? (
            <>
              <div className="col-md-6 order-1 order-md-2">
                <Carousel className="carousel-item-about">
                  {landscapeImagesJardins.map((img, index) => (
                    <Carousel.Item key={index}>
                      <img className="d-block w-100" src={img} alt={`Slide ${index + 1}`} />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
              <div className="col-md-5 order-2 order-md-1 p-5 text-center text-column">
                <h2 className="mb-5 text-blue">Construção de Jardins</h2>
                <p>
                  Construir um jardim é uma tarefa importante, o jardim é o conforto do nosso lar, é o local onde nos recriamos. A Hidro Lazer têm uma
                  equipa de profissionais que trabalha para lhe oferecer o melhor do seu jardim.
                </p>
                <a href="/Contacto">
                  <button className="btn mt-auto my-button-landscape">Saber mais</button>
                </a>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-5">
                <Carousel className="carousel-item-about">
                  {landscapeImagesJardins.map((img, index) => (
                    <Carousel.Item key={index}>
                      <img className="d-block w-100" src={img} alt={`Slide ${index + 1}`} />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
              <div className="col-md-5 p-5 text-center text-column">
                <h2 className="mb-5 text-blue">Construção de Jardins</h2>
                <p>
                  Construir um jardim é uma tarefa importante, o jardim é o conforto do nosso lar, é o local onde nos recriamos. A Hidro Lazer têm uma
                  equipa de profissionais que trabalha para lhe oferecer o melhor do seu jardim.
                </p>
                <a href="/Contacto">
                  <button className="btn mt-auto my-button-landscape">Saber mais</button>
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandscapeArchitecture;
