import React, { useEffect } from "react";
import { Canvas, useLoader, useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import "./Home.css"; // Assuming you want to use the same CSS file for styles

// Importing the GLB file
import model3D from "../../assets/Home/Piscinas/Piscinas3.0/piscinas.glb";

const Model = () => {
  const gltf = useLoader(GLTFLoader, model3D);

  return <primitive object={gltf.scene} scale={1} position={[-10, -10, 52]} />;
};

const CameraController = () => {
  const { camera } = useThree();
  useEffect(() => {
    camera.position.set(-15, 5, 5); // Set initial camera position
  }, [camera]);
  return null;
};

const ThreeDModelViewer = () => {
  const isSmallScreen = window.innerWidth <= 768;

  return (
    <div className="quote-form-container">
      <div className="quote-form-header-text mb-3">
        {isSmallScreen ? (
          <>
            <h5 className="quote-form-header">
              Projete a sua <span className="bold">PISCINA</span> com o nosso visualizador 3D. Vários modelos disponíveis para imaginar o seu espaço.
            </h5>
          </>
        ) : (
          <>
            <h5 className="quote-form-header">
              Projete a sua <span className="bold">PISCINA</span> com o nosso visualizador 3D.
            </h5>
            <h5 className="quote-form-header">Vários modelos disponíveis para imaginar o seu espaço.</h5>
          </>
        )}
        <h5 className="quote-form-header">
          <span className="bold">No fim, peça o seu orçamento</span>
        </h5>
      </div>
      <Canvas className="quote-form-box" style={{ background: "#FFFFFF" }}>
        <ambientLight intensity={0.5} />
        <directionalLight position={[0, 5, 5]} intensity={1} />
        <CameraController />
        <Model />
        <OrbitControls />
      </Canvas>
    </div>
  );
};

export default ThreeDModelViewer;

/*import React, { useEffect } from 'react';
import { Canvas, useLoader, useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import './Home.css'; // Assuming you want to use the same CSS file for styles

// Importing the OBJ and MTL files
import model3D from '../../assets/Home/Piscinas/Piscinas2.0/piscinas.obj';
import modelMaterials from '../../assets/Home/Piscinas/Piscinas2.0/piscinas.mtl';

// Importing texture images
import Concrete_Form from '../../assets/Home/Piscinas/Piscinas2.0/Concrete_Form_4x8.jpg';
import Square_Glass_Tile from '../../assets/Home/Piscinas/Piscinas2.0/Square_Glass_Tile_01.jpg';
import Water_Pool_Light from '../../assets/Home/Piscinas/Piscinas2.0/Water_Pool_Light.jpg';
import Concrete_Form_4x8_1 from '../../assets/Home/Piscinas/Piscinas2.0/_Concrete_Form_4x8_1.jpg';
import Square_Glass_Tile_01_1 from '../../assets/Home/Piscinas/Piscinas2.0/_Square_Glass_Tile_01_1.jpg';

const Model = () => {
  const materials = useLoader(MTLLoader, modelMaterials);
  const obj = useLoader(OBJLoader, model3D, (loader) => {
    materials.preload();
    loader.setMaterials(materials);
  });

  return <primitive object={obj} scale={1} position={[-10, -10, 32]} />;
};

const CameraController = () => {
  const { camera } = useThree();
  useEffect(() => {
    camera.position.set(-15, 5, 5); // Set initial camera position
  }, [camera]);
  return null;
};

const ThreeDModelViewer = () => {
  const isSmallScreen = window.innerWidth <= 768;

  return (
    <div className="quote-form-container">
      <div className="quote-form-header-text mb-3">
        {isSmallScreen ? (
          <>
            <h5 className="quote-form-header">Projete a sua <span className="bold">PISCINA</span> com o nosso visualizador 3D. Vários modelos disponíveis para imaginar o seu espaço.</h5>
          </>
        ) : (
          <>
            <h5 className="quote-form-header">Projete a sua <span className="bold">PISCINA</span> com o nosso visualizador 3D.</h5>
            <h5 className="quote-form-header">Vários modelos disponíveis para imaginar o seu espaço.</h5>
          </>
        )}
        <h5 className="quote-form-header"><span className="bold">No fim, peça o seu orçamento</span></h5>
      </div>
      <Canvas className="quote-form-box" style={{ background: '#FFFFFF' }}>
        <ambientLight intensity={0.5} />
        <directionalLight position={[0, 5, 5]} intensity={1} />
        <CameraController />
        <Model />
        <OrbitControls />
      </Canvas>
    </div>
  );
};

export default ThreeDModelViewer;
*/
