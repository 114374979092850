import React from 'react';
import Header from '../Header';
import CoversBanner from './CoversBanner';
import Footer from "../Footer"
import AutomaticCoversLayer1 from "./AutomaticCoversLayer1"
import AutomaticCoversLayer2 from "./AutomaticCoversLayer2"
import AutomaticCoversLayer3 from "./AutomaticCoversLayer3"
import AutomaticCoversLayer4 from "./AutomaticCoversLayer4"
import AutomaticCoversLayer5 from "./AutomaticCoversLayer5"
import './Covers';

function Covers() {
    return (
        <div>
            <Header/>
            <CoversBanner/>
            <AutomaticCoversLayer1/>
            <AutomaticCoversLayer2/>
            <AutomaticCoversLayer3/>
            <AutomaticCoversLayer4/>
            <AutomaticCoversLayer5/>
            <Footer/>
        </div>
    );
}

export default Covers;