import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const PortfolioBanner = () => {
  return (
    <div className="container-fluid bg-primary p-5">
      <div className="row">
        <div className="col-12 text-center">
          <h1 className="text-uppercase text-white text-animate">Portfólio</h1>
          <hr className="custom-hr text-animate" />
          <p className="lead text-white text-animate">Veja as nossas magnificas piscinas</p>
        </div>
      </div>
    </div>
  );
};

export default PortfolioBanner;
