import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./VacumCleaner.css"; // Ensure the CSS file is linked properly
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot, faPlug, faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import VacumCleanerESLynx from "../../assets/VacumCleaners/VacumCleanerESLynx.png"; // Adjust the path as necessary

const VacumCleanerLayer3 = () => {
  return (
    <div className="container-fluid automatic-covers">
      <div className="row">
        <div className="col-md-6 d-flex flex-column align-items-center">
          <div className="image-container">
            <img src={VacumCleanerESLynx} alt="Vacuum Cleaner ESLynx" className="cover-img-vacum" />
          </div>
        </div>
        <div className="col-md-6 text-section">
          <h2>ES Lynx - ECOPOOL©</h2>
          <p>O ES Lynx é particularmente econômico devido ao baixo consumo de energia.</p>
          <p>Como não está ligado ao sistema de filtração, o robô elétrico Lynx pode adaptar-se a qualquer piscina até 10 x 5 m.</p>
          <div className="row icon-features text-center mt-5">
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faRobot} size="3x" className="fa-icon custom-icon-color" />
              <p>Automático</p>
            </div>
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faPlug} size="3x" className="fa-icon custom-icon-color" />
              <p>Eficiente</p>
            </div>
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faShieldAlt} size="3x" className="fa-icon custom-icon-color" />
              <p>Segurança</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VacumCleanerLayer3;
