import React from "react";
import CompareImage from "react-compare-image";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BeforeAfter.css";
import BeforeAfter1_After from "../../assets/BeforeAfter/BeforeAfter1_After.jpg";
import BeforeAfter1_Before from "../../assets/BeforeAfter/BeforeAfter1_Before.jpg";
import BeforeAfter2_After from "../../assets/BeforeAfter/BeforeAfter2_After.jpg";
import BeforeAfter2_Before from "../../assets/BeforeAfter/BeforeAfter2_Before.jpg";
import BeforeAfter3_After from "../../assets/BeforeAfter/BeforeAfter3_After.jpg";
import BeforeAfter3_Before from "../../assets/BeforeAfter/BeforeAfter3_Before.jpg";
import BeforeAfter4_After from "../../assets/BeforeAfter/BeforeAfter4_After.jpg";
import BeforeAfter4_Before from "../../assets/BeforeAfter/BeforeAfter4_Before.jpeg";
import BeforeAfter5_After from "../../assets/BeforeAfter/BeforeAfter5_After.jpg";
import BeforeAfter5_Before from "../../assets/BeforeAfter/BeforeAfter5_Before.jpg";
import BeforeAfter6_After from "../../assets/BeforeAfter/BeforeAfter6_After.jpg";
import BeforeAfter6_Before from "../../assets/BeforeAfter/BeforeAfter6_Before.jpg";
import BeforeAfter7_After from "../../assets/BeforeAfter/BeforeAfter7_After.jpeg";
import BeforeAfter7_Before from "../../assets/BeforeAfter/BeforeAfter7_Before.jpeg";
import BeforeAfter8_After from "../../assets/BeforeAfter/BeforeAfter8_After.jpeg";
import BeforeAfter8_Before from "../../assets/BeforeAfter/BeforeAfter8_Before.jpeg";
import BeforeAfter9_After from "../../assets/BeforeAfter/BeforeAfter9_After.jpg";
import BeforeAfter9_Before from "../../assets/BeforeAfter/BeforeAfter9_Before.jpg";
import BeforeAfter10_After from "../../assets/BeforeAfter/BeforeAfter10_After.jpg";
import BeforeAfter10_Before from "../../assets/BeforeAfter/BeforeAfter10_Before.jpg";
import BeforeAfter11_After from "../../assets/BeforeAfter/BeforeAfter11_After.jpg";
import BeforeAfter11_Before from "../../assets/BeforeAfter/BeforeAfter11_Before.jpg";
import BeforeAfter12_After from "../../assets/BeforeAfter/BeforeAfter12_After.jpg";
import BeforeAfter12_Before from "../../assets/BeforeAfter/BeforeAfter12_Before.jpeg";

const services = [
  { before: BeforeAfter1_Before, after: BeforeAfter1_After },
  { before: BeforeAfter2_Before, after: BeforeAfter2_After },
  { before: BeforeAfter3_Before, after: BeforeAfter3_After },
  { before: BeforeAfter4_Before, after: BeforeAfter4_After },
  { before: BeforeAfter5_Before, after: BeforeAfter5_After },
  { before: BeforeAfter6_Before, after: BeforeAfter6_After },
  { before: BeforeAfter7_Before, after: BeforeAfter7_After },
  { before: BeforeAfter8_Before, after: BeforeAfter8_After },
  { before: BeforeAfter9_Before, after: BeforeAfter9_After },
  { before: BeforeAfter10_Before, after: BeforeAfter10_After },
  { before: BeforeAfter11_Before, after: BeforeAfter11_After },
  { before: BeforeAfter12_Before, after: BeforeAfter12_After },
];

const BeforeAfterSlider = () => {
  const imageStyles = {
    maxHeight: "400px",
  };

  return (
    <div className="container-fluid p-5 my-5 before-after-slider">
      <div className="row">
        {services.map((service, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className="compare-image-container service-item">
              <CompareImage
                leftImage={service.before}
                rightImage={service.after}
                leftImageCss={imageStyles}
                rightImageCss={imageStyles}
                handle={<div className="custom-handle"></div>}
              />
              <div className="compare-image-label before">Before</div>
              <div className="compare-image-label after">After</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BeforeAfterSlider;
