import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AutomaticCovers.css'; // Make sure this CSS file is available and properly linked
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPiggyBank, faRecycle, faWrench } from '@fortawesome/free-solid-svg-icons';
import AutomaticCoversLayer5_2 from "../../assets/Covers/AutomaticCoversLayer5_2.jpg";
import AutomaticCoversLayer5_3 from "../../assets/Covers/AutomaticCoversLayer5_3.jpg";

const AutomaticCoversLayer5 = () => {
  return (
    <div className="container-fluid automatic-covers">
      <div className="row">
        <div className="col-md-6 d-flex flex-column align-items-center align-self-center">
          <div className="image-container">
            <img src={AutomaticCoversLayer5_2} alt="Cover 2" className="cover-img" />
            <img src={AutomaticCoversLayer5_3} alt="Cover 3" className="cover-img" />
          </div>
        </div>
        <div className="col-md-6 text-section">
          <h2>Coberturas Isotérmicas</h2>
          <p>Com base em polietileno, e com tratamento anti-UV e antioxidante.</p>
          <div className="row icon-features text-center mt-5">
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faPiggyBank} size="3x" className="fa-icon custom-icon-color" />
              <p>+ Económica</p>
            </div>
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faRecycle} size="3x" className="fa-icon custom-icon-color" />
              <p>- Manutenção</p>
            </div>
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faWrench} size="3x" className="fa-icon custom-icon-color" />
              <p>Fácil Instalação</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AutomaticCoversLayer5;
