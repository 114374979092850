import React from 'react';
import Header from '../Header';
import MaintenanceBanner from './MaintenanceBanner';
import Footer from "../Footer"
import ServiceOptions from "./ServiceOptions"
import CircularImageRow from "./CircularImageRow"
import './Services.css'; // Make sure this is correctly linked

function Maintenance() {
    return (
        <div>
            <Header/>
            <MaintenanceBanner/>
            <ServiceOptions/>
            <Footer/>
        </div>
    );
}

export default Maintenance;