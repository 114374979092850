import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './greenAreas.css';

const GreenAreasBanner = () => {
    return (
        <div className="container-fluid bg-primary p-5">
            <div className="row">
                <div className="col-12 text-center">
                    <h1 className="text-uppercase text-white text-animate">ESPAÇOS VERDES</h1>
                    <hr className="custom-hr text-animate" />
                    <p className="lead text-white text-animate">Fornecemos uma grande variedade de serviços profissionais para atender às suas necessidades.</p>
                </div>
            </div>
        </div>
    );
};

export default GreenAreasBanner;
