import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const VacumCleanerBanner = () => {
    return (
        <div className="container-fluid bg-primary p-5">
          <div className="row">
                    <div className="col-12 text-center">
                        <h1 className="text-uppercase text-white text-animate">Aspiradores</h1>
                        <hr className="custom-hr text-animate" />
                        <p className="lead text-white text-animate">Veja os vários aspiradores para a sua piscina.</p>
                    </div>
                </div>
        </div>
      );
};

export default VacumCleanerBanner;
