import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Portfolio.css"; // Ensure this CSS file is linked properly
import ImageA from "../../assets/Portfolio/PortfolioA.webp";
import ImageB from "../../assets/Portfolio/PortfolioB.webp";
import ImageC from "../../assets/Portfolio/PortfolioC.webp";
import ImageD from "../../assets/Portfolio/PortfolioD.webp";
// Add as many images as needed
import ImageE from "../../assets/Portfolio/PortfolioE.webp";
import ImageF from "../../assets/Portfolio/PortfolioF.webp";

import Image1 from "../../assets/Portfolio/Portfolio (1).jpg";
import Image2 from "../../assets/Portfolio/Portfolio (2).jpg";
import Image3 from "../../assets/Portfolio/Portfolio (3).jpg";
import Image4 from "../../assets/Portfolio/Portfolio (4).jpg";
import Image5 from "../../assets/Portfolio/Portfolio (5).jpg";
import Image6 from "../../assets/Portfolio/Portfolio (6).jpg";
import Image7 from "../../assets/Portfolio/Portfolio (7).jpg";
import Image8 from "../../assets/Portfolio/Portfolio (8).jpg";
import Image9 from "../../assets/Portfolio/Portfolio (9).jpg";
import Image10 from "../../assets/Portfolio/Portfolio (10).jpg";
import Image11 from "../../assets/Portfolio/Portfolio (11).jpg";
import Image12 from "../../assets/Portfolio/Portfolio (12).jpg";
import Image13 from "../../assets/Portfolio/Portfolio (13).jpg";
import Image14 from "../../assets/Portfolio/Portfolio (14).jpg";
import Image15 from "../../assets/Portfolio/Portfolio (15).jpg";
import Image16 from "../../assets/Portfolio/Portfolio (16).jpg";
import Image17 from "../../assets/Portfolio/Portfolio (17).jpg";
import Image18 from "../../assets/Portfolio/Portfolio (18).jpg";
import Image19 from "../../assets/Portfolio/Portfolio (19).jpg";
import Image20 from "../../assets/Portfolio/Portfolio (20).jpg";
import Image21 from "../../assets/Portfolio/Portfolio (21).jpg";
import Image22 from "../../assets/Portfolio/Portfolio (22).jpg";
import Image23 from "../../assets/Portfolio/Portfolio (23).jpg";
import Image24 from "../../assets/Portfolio/Portfolio (24).jpg";
import Image25 from "../../assets/Portfolio/Portfolio (25).jpg";
import Image26 from "../../assets/Portfolio/Portfolio (26).jpg";
import Image27 from "../../assets/Portfolio/Portfolio (27).jpg";
import Image28 from "../../assets/Portfolio/Portfolio (28).jpg";
import Image29 from "../../assets/Portfolio/Portfolio (29).jpg";
import Image30 from "../../assets/Portfolio/Portfolio (30).jpg";
import Image31 from "../../assets/Portfolio/Portfolio (31).jpg";
import Image32 from "../../assets/Portfolio/Portfolio (32).jpg";
import Image33 from "../../assets/Portfolio/Portfolio (33).jpg";
import Image34 from "../../assets/Portfolio/Portfolio (34).jpg";
import Image35 from "../../assets/Portfolio/Portfolio (35).jpg";
import Image36 from "../../assets/Portfolio/Portfolio (36).jpg";
import Image37 from "../../assets/Portfolio/Portfolio (37).jpg";
import Image38 from "../../assets/Portfolio/Portfolio (38).jpg";
import Image39 from "../../assets/Portfolio/Portfolio (39).jpg";
import Image40 from "../../assets/Portfolio/Portfolio (40).jpg";
import Image41 from "../../assets/Portfolio/Portfolio (41).jpg";
import Image42 from "../../assets/Portfolio/Portfolio (42).jpg";
import Image43 from "../../assets/Portfolio/Portfolio (43).jpg";
import Image44 from "../../assets/Portfolio/Portfolio (44).jpg";
import Image45 from "../../assets/Portfolio/Portfolio (45).jpg";
import Image46 from "../../assets/Portfolio/Portfolio (46).jpg";
import Image47 from "../../assets/Portfolio/Portfolio (47).jpg";
import Image48 from "../../assets/Portfolio/Portfolio (48).jpg";
import Image49 from "../../assets/Portfolio/Portfolio (49).jpg";
import Image50 from "../../assets/Portfolio/Portfolio (50).jpg";
import Image51 from "../../assets/Portfolio/Portfolio (51).jpg";
import Image52 from "../../assets/Portfolio/Portfolio (52).jpg";
import Image53 from "../../assets/Portfolio/Portfolio (53).jpg";
import Image54 from "../../assets/Portfolio/Portfolio (54).jpg";
import Image55 from "../../assets/Portfolio/Portfolio (55).jpg";
import Image56 from "../../assets/Portfolio/Portfolio (56).jpg";
import Image57 from "../../assets/Portfolio/Portfolio (57).jpg";
import Image58 from "../../assets/Portfolio/Portfolio (58).jpg";
import Image59 from "../../assets/Portfolio/Portfolio (59).jpg";
import Image60 from "../../assets/Portfolio/Portfolio (60).jpg";
import Image61 from "../../assets/Portfolio/Portfolio (61).jpg";
import Image62 from "../../assets/Portfolio/Portfolio (62).jpg";
import Image63 from "../../assets/Portfolio/Portfolio (63).jpg";
import Image64 from "../../assets/Portfolio/Portfolio (64).jpg";
import Image65 from "../../assets/Portfolio/Portfolio (65).jpg";
import Image66 from "../../assets/Portfolio/Portfolio (66).jpg";
import Image67 from "../../assets/Portfolio/Portfolio (67).jpg";
import Image68 from "../../assets/Portfolio/Portfolio (68).jpg";
import Image69 from "../../assets/Portfolio/Portfolio (69).jpg";
import Image70 from "../../assets/Portfolio/Portfolio (70).jpg";
import Image71 from "../../assets/Portfolio/Portfolio (71).jpg";
import Image72 from "../../assets/Portfolio/Portfolio (72).jpg";
import Image73 from "../../assets/Portfolio/Portfolio (73).jpg";
import Image74 from "../../assets/Portfolio/Portfolio (74).jpg";
import Image75 from "../../assets/Portfolio/Portfolio (75).jpg";
import Image76 from "../../assets/Portfolio/Portfolio (76).jpg";
import Image77 from "../../assets/Portfolio/Portfolio (77).jpg";
import Image78 from "../../assets/Portfolio/Portfolio (78).jpg";
import Image79 from "../../assets/Portfolio/Portfolio (79).jpg";
import Image80 from "../../assets/Portfolio/Portfolio (80).jpg";
import Image81 from "../../assets/Portfolio/Portfolio (81).jpg";
import Image82 from "../../assets/Portfolio/Portfolio (82).jpg";
import Image83 from "../../assets/Portfolio/Portfolio (83).jpg";
import Image84 from "../../assets/Portfolio/Portfolio (84).jpg";
import Image85 from "../../assets/Portfolio/Portfolio (85).jpg";
import Image86 from "../../assets/Portfolio/Portfolio (86).jpg";
import Image87 from "../../assets/Portfolio/Portfolio (87).jpg";
import Image88 from "../../assets/Portfolio/Portfolio (88).jpg";
import Image89 from "../../assets/Portfolio/Portfolio (89).jpg";
import Image90 from "../../assets/Portfolio/Portfolio (90).jpg";
import Image91 from "../../assets/Portfolio/Portfolio (91).jpg";
import Image92 from "../../assets/Portfolio/Portfolio (92).jpg";
import Image93 from "../../assets/Portfolio/Portfolio (93).jpg";
import Image94 from "../../assets/Portfolio/Portfolio (94).jpg";
import Image95 from "../../assets/Portfolio/Portfolio (95).jpg";
import Image96 from "../../assets/Portfolio/Portfolio (96).jpg";
import Image97 from "../../assets/Portfolio/Portfolio (97).jpg";
import Image98 from "../../assets/Portfolio/Portfolio (98).jpg";
import Image99 from "../../assets/Portfolio/Portfolio (99).jpg";
import Image100 from "../../assets/Portfolio/Portfolio (100).jpg";
import Image101 from "../../assets/Portfolio/Portfolio (101).jpg";
import Image102 from "../../assets/Portfolio/Portfolio (102).jpg";
import Image103 from "../../assets/Portfolio/Portfolio (103).jpg";
import Image104 from "../../assets/Portfolio/Portfolio (104).jpg";
import Image105 from "../../assets/Portfolio/Portfolio (105).jpg";
import Image106 from "../../assets/Portfolio/Portfolio (106).jpg";
import Image107 from "../../assets/Portfolio/Portfolio (107).jpg";
import Image108 from "../../assets/Portfolio/Portfolio (108).jpg";
import Image109 from "../../assets/Portfolio/Portfolio (109).jpg";
import Image110 from "../../assets/Portfolio/Portfolio (110).jpg";
import Image111 from "../../assets/Portfolio/Portfolio (111).jpg";
import Image112 from "../../assets/Portfolio/Portfolio (112).jpg";
import Image113 from "../../assets/Portfolio/Portfolio (113).jpg";
import Image114 from "../../assets/Portfolio/Portfolio (114).jpg";
import Image115 from "../../assets/Portfolio/Portfolio (115).jpg";
import Image116 from "../../assets/Portfolio/Portfolio (116).jpg";
import Image117 from "../../assets/Portfolio/Portfolio (117).jpg";
import Image118 from "../../assets/Portfolio/Portfolio (118).jpg";
import Image119 from "../../assets/Portfolio/Portfolio (119).jpg";
import Image120 from "../../assets/Portfolio/Portfolio (120).jpg";
import Image121 from "../../assets/Portfolio/Portfolio (121).jpg";
import Image122 from "../../assets/Portfolio/Portfolio (122).jpg";
import Image123 from "../../assets/Portfolio/Portfolio (123).jpg";
import Image124 from "../../assets/Portfolio/Portfolio (124).jpg";
import Image125 from "../../assets/Portfolio/Portfolio (125).jpg";
import Image126 from "../../assets/Portfolio/Portfolio (126).jpg";
import Image127 from "../../assets/Portfolio/Portfolio (127).jpg";
import Image128 from "../../assets/Portfolio/Portfolio (128).jpg";
import Image129 from "../../assets/Portfolio/Portfolio (129).jpg";
import Image130 from "../../assets/Portfolio/Portfolio (130).jpg";
import Image131 from "../../assets/Portfolio/Portfolio (131).jpg";
import Image132 from "../../assets/Portfolio/Portfolio (90).jpeg";
import Image133 from "../../assets/Portfolio/Portfolio (91).jpeg";

const images = [
  ImageA,
  ImageB,
  ImageC,
  ImageD,
  ImageE,
  ImageF,
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
  Image11,
  Image12,
  Image13,
  Image14,
  Image15,
  Image16,
  Image17,
  Image18,
  Image19,
  Image20,
  Image21,
  Image22,
  Image23,
  Image24,
  Image25,
  Image26,
  Image27,
  Image28,
  Image29,
  Image30,
  Image31,
  Image32,
  Image33,
  Image34,
  Image35,
  Image36,
  Image37,
  Image38,
  Image39,
  Image40,
  Image41,
  Image42,
  Image43,
  Image44,
  Image45,
  Image46,
  Image47,
  Image48,
  Image49,
  Image50,
  Image51,
  Image52,
  Image53,
  Image54,
  Image55,
  Image56,
  Image57,
  Image58,
  Image59,
  Image60,
  Image61,
  Image62,
  Image63,
  Image64,
  Image65,
  Image66,
  Image67,
  Image68,
  Image69,
  Image70,
  Image71,
  Image72,
  Image73,
  Image74,
  Image75,
  Image76,
  Image77,
  Image78,
  Image79,
  Image80,
  Image81,
  Image82,
  Image83,
  Image84,
  Image85,
  Image86,
  Image87,
  Image88,
  Image89,
  Image90,
  Image91,
  Image92,
  Image93,
  Image94,
  Image95,
  Image96,
  Image97,
  Image98,
  Image99,
  Image100,
  Image101,
  Image102,
  Image103,
  Image104,
  Image105,
  Image106,
  Image107,
  Image108,
  Image109,
  Image110,
  Image111,
  Image112,
  Image113,
  Image114,
  Image115,
  Image116,
  Image117,
  Image118,
  Image119,
  Image120,
  Image121,
  Image122,
  Image123,
  Image124,
  Image125,
  Image126,
  Image127,
  Image128,
  Image129,
  Image130,
  Image131,
  Image132,
  Image133,
];

const ImageGrid = () => {
  return (
    <div className="container-fluid my-5">
      <div className="row">
        {images.map((image, index) => (
          <div className="col-md-4 col-sm-6 col-12 mb-4 d-flex justify-content-center" key={index}>
            <div className="image-wrapper">
              <img src={image} alt={`Portfolio ${index + 1}`} className="img-fluid" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGrid;
