import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./EquipmentProducts.css"; // Ensure this CSS file is linked properly
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlask, faShieldAlt, faBiohazard } from "@fortawesome/free-solid-svg-icons";
import Floco from "../../assets/EquipmentProducts/PRODUTOS QUIMICOS.png"; // Adjust the path as necessary

const EquipmentProductsLayer1 = () => {
  return (
    <div className="container-fluid equipment-product-layout">
      <div className="row">
        <div className="col-md-6 d-flex flex-column align-items-center justify-content-around">
          <div className="image-container">
            <img src={Floco} alt="Produtos Químicos" className="cover-img" />
          </div>
        </div>
        <div className="col-md-6 text-section">
          <h2>Produtos químicos</h2>
          <p>
            A sua piscina está constantemente exposta a poluições como pó, folhas, pólen, transpiração humana, produtos cosméticos, protetores solares
            entre outros. Só a manutenção de níveis químicos corretos pode protegê-la destas ameaças e mantê-la livre de bactérias, algas, fungos,
            corrosão, etc. Consulte as nossas recomendações
          </p>
          <div className="row icon-features text-center mt-5">
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faFlask} size="3x" className="fa-icon custom-icon-color" />
              <p>Qualidade</p>
            </div>
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faShieldAlt} size="3x" className="fa-icon custom-icon-color" />
              <p>Segurança</p>
            </div>
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faBiohazard} size="3x" className="fa-icon custom-icon-color" />
              <p>Controle</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquipmentProductsLayer1;
