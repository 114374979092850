import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTree, faHammer, faSeedling } from '@fortawesome/free-solid-svg-icons';
import './greenAreas.css'; // Ensure this is correctly linked

const ServiceOptions = () => {
  return (
    <div className="container my-5">
      <div className="row icon-features text-center">
        <div className="col-md-3 feature-item">
          <FontAwesomeIcon icon={faTree} size="3x" className="fa-icon custom-icon-color" />
          <h4>Projecto de Jardins</h4>
          <p>Temos um serviço de Arquitectura Paisagista que garante as melhores soluções para o seu jardim.</p>
        </div>
        <div className="col-md-3 feature-item">
          <FontAwesomeIcon icon={faHammer} size="3x" className="fa-icon custom-icon-color" />
          <h4>Construção de Jardins</h4>
          <p>Temos uma equipa disponível para construir o seu jardim.</p>
        </div>
        <div className="col-md-3 feature-item">
          <FontAwesomeIcon icon={faSeedling} size="3x" className="fa-icon custom-icon-color" />
          <h4>Manutenção de Jardins</h4>
          <p>Temos um serviço de jardinagem para manter sempre impecável o seu jardim.</p>
        </div>
      </div>
    </div>
  );
}

export default ServiceOptions;
