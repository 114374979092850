import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./BeforeAfter.css";

const BeforeAfterBanner = () => {
    return (
        <div className="container-fluid bg-primary p-5">
            <div className="row">
                <div className="col-12 text-center">
                    <h1 className="text-uppercase text-white text-animate">ANTES E DEPOIS</h1>
                    <hr className="custom-hr text-animate" />
                    <p className="lead text-white text-animate">Veja a Transformação dos nossos espaços, inspire-se.</p>
                </div>
            </div>
        </div>
    );
};

export default BeforeAfterBanner;
