import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AutomaticCovers.css';  // Ensure the CSS file is linked properly
import AutomaticCoversLayer3_1 from "../../assets/Covers/AutomaticCoversLayer3_1.webp";
import AutomaticCoversLayer3_2 from "../../assets/Covers/AutomaticCoversLayer3_2.webp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPiggyBank, faTools, faScrewdriver } from '@fortawesome/free-solid-svg-icons';

const AutomaticCoversLayer3 = () => {
  return (
    <div className="container-fluid automatic-covers">
      <div className="row">
        <div className="col-md-6 d-flex flex-column align-items-center align-self-center">
          <div className="image-container">
            <img src={AutomaticCoversLayer3_1} alt="Cover 1" className="cover-img" />
            <img src={AutomaticCoversLayer3_2} alt="Cover 2" className="cover-img" />
          </div>
        </div>
        <div className="col-md-6 text-section">
          <h2>Coberturas de Inverno</h2>
          <p>Tela de PVC maleável, com tratamento anti UV e antioxidante.</p>
          <div className="row icon-features text-center mt-5">
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faPiggyBank} size="3x" className="fa-icon custom-icon-color" />
              <p>+ Económica</p>
            </div>
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faTools} size="3x" className="fa-icon custom-icon-color" />
              <p>- Manutenção</p>
            </div>
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faScrewdriver} size="3x" className="fa-icon custom-icon-color" />
              <p>Fácil Instalação</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AutomaticCoversLayer3;
