import React, { useRef } from "react";
import emailjs from "emailjs-com";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("O email foi enviado com sucesso");
          form.current.reset(); // Clear the form fields
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send the message, please try again.");
        }
      );
  };

  return (
    <div className="container-fluid p-5 my-5 contact-form">
      <div className="row">
        <div className="col-md-4">
          <h2>Contacto</h2>
          <h5>TELEFONE</h5>
          <p>
            <a href="tel:+351243719232">+351 243 719 232</a>
          </p>
          <p>
            <a href="tel:+351916421075">+351 916 421 075</a>
          </p>
          <h5>E-MAIL</h5>
          <p>
            <a href="mailto:geral@hidro-lazer.com">geral@hidro-lazer.com</a>
          </p>
          <h5>LOJA</h5>
          <p>
            <a href="https://www.google.com/maps/search/?api=1&query=Rua+Serpa+Pinto+49+A/r/c+Cartaxo" target="_blank" rel="noopener noreferrer">
              Rua Serpa Pinto, 49A r/c 2070-116 Cartaxo
            </a>
          </p>
          <div>
            <a href="https://www.facebook.com/hidrolazer.piscinas.1" target="_blank" className="social-icon-contacts">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="https://www.instagram.com/hidrolazer_/" target="_blank" className="social-icon-contacts">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="mailto:geral@hidro-lazer.com" className="social-icon-contacts">
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </div>
        </div>
        <div className="col-md-8 service-item">
          <form ref={form} onSubmit={sendEmail}>
            <div className="form-group form-control-sm">
              <input type="text" name="from_name" className="form-control" placeholder="Nome" required />
            </div>
            <div className="form-group form-control-sm">
              <input type="email" name="from_email" className="form-control" placeholder="E-mail" required />
            </div>
            <div className="form-group form-control-sm">
              <input type="text" name="from_phone" className="form-control" placeholder="Telefone" required />
            </div>
            <div className="form-group form-control-sm">
              <input type="morada" name="from_adress" className="form-control" placeholder="Morada" required />
            </div>
            <div className="form-group form-control-sm">
              <textarea name="message" className="form-control" placeholder="Mensagem" rows="3" required></textarea>
            </div>
            <button type="submit" className="btn btn-custom my-button-contact">
              ENVIAR
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
