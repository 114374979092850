import React from 'react';
import Header from '../Header';
import ContactForm from './ContactForm';
import ContactBanner from './ContactBanner';
import Footer from "../Footer";
import './Contact.css';

function Contact() {
    return (
        <div>
            <Header/>
            <ContactBanner/>
            <ContactForm/>
            {/* <MapComponent />*/}
            <Footer/>
        </div>
    );
}

export default Contact;
