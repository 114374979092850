import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./VacumCleaner.css"; // Ensure the CSS file is linked properly
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot, faBolt, faFeatherAlt } from "@fortawesome/free-solid-svg-icons";
import VacumCleanerCosmytheBot200 from "../../assets/VacumCleaners/VacumCleanerCosmytheBot200.png"; // Adjust the path as necessary

const VacumCleanerLayer2 = () => {
  const isSmallScreen = window.innerWidth <= 768;

  return (
    <div className="container-fluid automatic-covers blue-background">
      <div className="row">
        {isSmallScreen ? (
          <>
            <div className="col-md-6 order-1 order-md-2 d-flex flex-column align-items-center">
              <div className="image-container">
                <img src={VacumCleanerCosmytheBot200} alt="Cosmy the Bot 200" className="cover-img-vacum" />
              </div>
            </div>
            <div className="col-md-6 order-2 order-md-1 text-section">
              <h2 className="text-white">Cosmy the Bot 200 - BWT©</h2>
              <p className="text-white">
                O Cosmy the Bot 200 está apto para limpeza do fundo, paredes e linha de água de piscinas pequenas ou sobreelevadas.
              </p>
              <p className="text-white"> Limpeza rápida graças ao ciclo de apenas uma hora e meia. </p>
              <div className="row icon-features text-center mt-5">
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faRobot} size="3x" className="fa-icon custom-icon-color" />
                  <p>Automático</p>
                </div>
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faBolt} size="3x" className="fa-icon custom-icon-color" />
                  <p>Alta Potência</p>
                </div>
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faFeatherAlt} size="3x" className="fa-icon custom-icon-color" />
                  <p>Leve</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-md-6 text-section">
              <h2 className="text-white">Cosmy the Bot 200 - BWT©</h2>
              <p className="text-white">
                O O Cosmy the Bot 200 está apto para limpeza do fundo, paredes e linha de água de piscinas pequenas ou sobreelevadas.
              </p>
              <p className="text-white"> Limpeza rápida graças ao ciclo de apenas uma hora e meia. </p>
              <div className="row icon-features text-center mt-5">
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faRobot} size="3x" className="fa-icon custom-icon-color" />
                  <p>Automático</p>
                </div>
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faBolt} size="3x" className="fa-icon custom-icon-color" />
                  <p>Alta Potência</p>
                </div>
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faFeatherAlt} size="3x" className="fa-icon custom-icon-color" />
                  <p>Leve</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex flex-column align-items-center">
              <div className="image-container">
                <img src={VacumCleanerCosmytheBot200} alt="Cosmy the Bot 200" className="cover-img-vacum" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VacumCleanerLayer2;
