import React from 'react';
import Header from '../Header';
import GreenAreasBanner from './GreenAreasBanner';
import Footer from "../Footer";
import ServiceOptions from "./ServiceOptions";
import LandscapeArchitecture from "./LandscapeArchitecture";

import './greenAreas.css';

function GreenAreas() {
    return (
        <div>
            <Header />
            <GreenAreasBanner />
            <ServiceOptions />
            <LandscapeArchitecture />
            <Footer />
        </div>
    );
}

export default GreenAreas;
