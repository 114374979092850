import React from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Home.css";

const CustomCarousel = ({ images }) => {
  return (
    <Carousel className="custom-carousel">
      {images.map((media, index) => (
        <Carousel.Item key={index} className="carousel-item">
          {media.type === "image" ? (
            <img src={media.src} className="d-block w-100" alt={`Slide ${index + 1}`} style={{ height: "100vh", objectFit: "cover" }} />
          ) : (
            <video className="d-block w-100" muted playsInline autoPlay loop style={{ height: "100vh", objectFit: "cover" }}>
              <source src={media.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          <Carousel.Caption className="carousel-caption">
            <h5 className="caption-title mt-5">
              Imagine your pool <span className="bold"> with us!</span>
            </h5>
            <h5 className="caption-title">
              Build your pool <span className="bold"> with us!</span>
            </h5>
            <a href="/Portfolio" className="btn my-button mt-5">
              VER MAIS
            </a>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CustomCarousel;
