import React, { useEffect, useState } from "react";
import Header from "../Header";
import CustomCarousel from "./Carousel";
import Banner from "./Banner";
import ServicesGrid from "./ServicesGrid";
import QuoteForm from "./QuoteForm";
import BackgroundWithCards from "./BackgroundWithCards";
import ThreeDModelViewer from "./ThreeDModelViewer";
import Footer from "../Footer";
import video1 from "../../assets/Carousel/Video1.webm";
import video2 from "../../assets/Carousel/Video2.webm";
import image1 from "../../assets/Carousel/1.jpg";
import image2 from "../../assets/Carousel/2.jpg";
import image3 from "../../assets/Carousel/3.jpg";
import image4 from "../../assets/Carousel/4.jpg";
import image5 from "../../assets/Carousel/5.jpg";
import image6 from "../../assets/Carousel/6.jpg";
import "./Home.css";

function Home() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const media = isSmallScreen
    ? [
        { type: "image", src: image1 },
        { type: "image", src: image2 },
        { type: "image", src: image3 },
        { type: "image", src: image4 },
        { type: "image", src: image5 },
        { type: "image", src: image6 },
      ]
    : [
        { type: "video", src: video1 },
        { type: "video", src: video2 },
      ];

  return (
    <div>
      <Header />
      <CustomCarousel images={media} />
      <Banner />
      <BackgroundWithCards />
      <ServicesGrid />
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-7 col-sm-12 mb-4">
            <ThreeDModelViewer />
          </div>
          <div className="col-lg-4 col-md-5 col-sm-12">
            <QuoteForm />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
