import React from 'react';
import ChaveInteligente from "../../assets/ServicesGrid/chave-inteligente.png";
import RespostaRapida from "../../assets/ServicesGrid/resposta-rapida.png";
import Tools from "../../assets/ServicesGrid/servico-tecnico.png";
import TrabalhoFeito from "../../assets/ServicesGrid/trabalho-feito.png";
import './About.css';


const AboutServicesGrid = () => {
    return (
            <div className="container-fluid  p-5">
                <div className="services-grid">
                    <div className="grid-item service-item">
                        <img src={RespostaRapida} alt="Resposta rápida" />
                        <h4>Resposta rápida</h4>
                        <p>Atendimento personalizado para todos o país.</p>
                    </div>
                    <div className="grid-item service-item">
                        <img src={Tools} alt="Serviço Profissional" />
                        <h4>Serviço Profissional</h4>
                        <p>Os nossos técnicos são profissionais que o aconselharão na escolha das melhores soluções.</p>
                    </div>
                    <div className="grid-item service-item">
                        <img src={TrabalhoFeito} alt="Garantia de qualidade" />
                        <h4>Garantia de qualidade</h4>
                        <p>Garantimos a qualidade e a brevidade desejada para a sua obra.</p>
                    </div>
                    <div className="grid-item service-item">
                        <img src={ChaveInteligente} alt="Chave na Mão" />
                        <h4>Chave na Mão</h4>
                        <p>Orçamentos gratuitos e sem compromissos. Obras de chave na mão.</p>
                    </div>
                </div>
            </div>
    );
}

export default AboutServicesGrid;
