import React from "react";
import "./Home.css"; // Ensure this file includes the necessary styles

const Banner = () => {
  const isSmallScreen = window.innerWidth <= 768;

  return (
    <div className="banner-container">
      <div className="banner-content">
        <div className="banner-text">
          {isSmallScreen ? (
            <>
              <p className="banner-mission">
                Construir uma <span className="bold">PISCINA</span> é mais do que criar um espaço de lazer. É investir em momentos de{" "}
                <span className="bold">felicidade, diversão e convívio</span>
              </p>
              <p className="banner-mission">
                A <span className="bold">HIDRO</span>LAZER transforma os seus sonhos em <span className="underline">realidade!</span>
              </p>
            </>
          ) : (
            <>
              <p className="banner-mission">
                Construir uma <span className="bold">PISCINA</span> é mais do que criar um espaço de lazer
              </p>
              <p className="banner-mission">
                É investir em momentos de <span className="bold">felicidade, diversão e convívio</span>
              </p>
              <p className="banner-mission">
                A <span className="bold">HIDRO</span>LAZER transforma os seus sonhos em <span className="underline">realidade!</span>
              </p>
            </>
          )}
        </div>
        <div className="banner-button">
          <a href="/Portfolio" className="my-button-portfolio">
            PORTFÓLIO
          </a>
        </div>
      </div>
    </div>
  );
};

export default Banner;
