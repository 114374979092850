import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./VacumCleaner.css"; // Ensure the CSS file is linked properly
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot, faTrashAlt, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import VacumCleanerSWY3500SWEEPY from "../../assets/VacumCleaners/VacumCleanerSWY3500SWEEPY.png"; // Adjust the path as necessary

const VacumCleanerLayer5 = () => {
  return (
    <div className="container-fluid automatic-covers">
      <div className="row">
        <div className="col-md-6 d-flex flex-column align-items-center">
          <div className="image-container">
            <img src={VacumCleanerSWY3500SWEEPY} alt="Vacuum Cleaner SWY3500SWEEPY" className="cover-img-vacum" />
          </div>
        </div>
        <div className="col-md-6 text-section">
          <h2>SWY 3500 SWEEPY - ZODIAC©</h2>
          <p>O SWY 3500 SWEEPY está apto para limpeza do fundo, paredes e linha de água.</p>
          <p>
            Aspiração ciclónica patenteada da Zodiac© garante o desempenho do robot Sweepy® ao longo do ciclo de limpeza. Remove os detritos da sua
            piscina com uma escova frontal ativa ao longo do ciclo, que limpa o solo, as paredes e a linha de água.
          </p>
          <div className="row icon-features text-center mt-5">
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faRobot} size="3x" className="fa-icon custom-icon-color" />
              <p>Inteligente</p>
            </div>
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faTrashAlt} size="3x" className="fa-icon custom-icon-color" />
              <p>Alta Capacidade</p>
            </div>
            <div className="col-md-3 feature-item">
              <FontAwesomeIcon icon={faSyncAlt} size="3x" className="fa-icon custom-icon-color" />
              <p>Ciclo Contínuo</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VacumCleanerLayer5;
