import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AutomaticCovers.css'; // Ensure the CSS file is linked properly
import AutomaticCoversLayer4_1 from "../../assets/Covers/AutomaticCoversLayer4_1.webp";
import AutomaticCoversLayer4_2 from "../../assets/Covers/AutomaticCoversLayer4_2.webp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faRecycle, faHourglassStart } from '@fortawesome/free-solid-svg-icons';

const AutomaticCoversLayer4 = () => {
  const isSmallScreen = window.innerWidth <= 768;

  return (
    <div className="container-fluid automatic-covers blue-background">
      <div className="row">
        {isSmallScreen ? (
          <>
            <div className="col-md-6 order-1 order-md-2 d-flex flex-column align-items-center align-self-center">
              <div className="image-container">
                <img src={AutomaticCoversLayer4_1} alt="Cover 1" className="cover-img" />
                <img src={AutomaticCoversLayer4_2} alt="Cover 2" className="cover-img" />
              </div>
            </div>
            <div className="col-md-6 order-2 order-md-1 text-section">
              <h2 className="text-white">Coberturas de Barras</h2>
              <p className="text-white">Tela de PVC maleável, com tratamento anti UV e antioxidante. Idêntico às coberturas de Inverno. Com barras de alumínio à largura da piscina, assentes na bordadura, melhorando a resistência ao peso.</p>
              <div className="row icon-features text-center mt-5">
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faLock} size="3x" className="fa-icon custom-icon-white" />
                  <p>+ Segurança</p>
                </div>
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faRecycle} size="3x" className="fa-icon custom-icon-white" />
                  <p>- Manutenção</p>
                </div>
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faHourglassStart} size="3x" className="fa-icon custom-icon-white" />
                  <p>+ Duração</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-md-6 text-section">
              <h2 className="text-white">Coberturas de Barras</h2>
              <p className="text-white">Tela de PVC maleável, com tratamento anti UV e antioxidante. Idêntico às coberturas de Inverno. Com barras de alumínio à largura da piscina, assentes na bordadura, melhorando a resistência ao peso.</p>
              <div className="row icon-features text-center mt-5">
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faLock} size="3x" className="fa-icon custom-icon-white" />
                  <p>+ Segurança</p>
                </div>
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faRecycle} size="3x" className="fa-icon custom-icon-white" />
                  <p>- Manutenção</p>
                </div>
                <div className="col-md-3 feature-item">
                  <FontAwesomeIcon icon={faHourglassStart} size="3x" className="fa-icon custom-icon-white" />
                  <p>+ Duração</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex flex-column align-items-center align-self-center">
              <div className="image-container">
                <img src={AutomaticCoversLayer4_1} alt="Cover 1" className="cover-img" />
                <img src={AutomaticCoversLayer4_2} alt="Cover 2" className="cover-img" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AutomaticCoversLayer4;
