import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './BeforeAfter.css'; // Ensure this CSS file is linked properly
import BeforeAfterVideo1 from '../../assets/BeforeAfter/Videos/BeforeAfterVideo1.mp4';
import BeforeAfterVideo2 from '../../assets/BeforeAfter/Videos/BeforeAfterVideo2.mov';
import BeforeAfterVideo3 from '../../assets/BeforeAfter/Videos/BeforeAfterVideo3.mp4';
import BeforeAfterVideo4 from '../../assets/BeforeAfter/Videos/BeforeAfterVideo4.mp4';

const VideoDisplayLayer = () => {
  const [loading, setLoading] = useState([true, true, true, true]);

  const handleLoadedData = (index) => {
    const newLoading = [...loading];
    newLoading[index] = false;
    setLoading(newLoading);
  };

  const videos = [
    BeforeAfterVideo1,
    BeforeAfterVideo2,
    BeforeAfterVideo3,
    BeforeAfterVideo4
  ];

  return (
    <div className="container-fluid my-5 before-after-slider">
      <div className="row">
        {videos.slice(0, 2).map((video, index) => (
          <div className="col-md-6 d-flex flex-column align-items-center" key={index}>
            <div className="compare-image-container">
              {loading[index] && <div className="loading-spinner">Loading...</div>}
              <video width="100%" height="100%" controls onLoadedData={() => handleLoadedData(index)}>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        ))}
      </div>
      <div className="row mt-4">
        {videos.slice(2, 4).map((video, index) => (
          <div className="col-md-6 d-flex flex-column align-items-center" key={index + 2}>
            <div className="compare-image-container">
              {loading[index + 2] && <div className="loading-spinner">Loading...</div>}
              <video width="100%" height="100%" controls onLoadedData={() => handleLoadedData(index + 2)}>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default VideoDisplayLayer;
