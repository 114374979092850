import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faShoppingCart, faWrench, faSwimmer, faDraftingCompass, faTree } from '@fortawesome/free-solid-svg-icons';
import './Services.css'; // Ensure this is correctly linked

const ServiceOptions = () => {
  return (
    <div className="container my-5">
      <div className="row text-center">
        <div className="col-md-4 service-item">
          <FontAwesomeIcon icon={faCogs} size="3x" className="fa-icon custom-icon-color" />
          <h4>Contratos de Serviços</h4>
          <p>Temos serviços de manutenção anuais/mensais/semanais.</p>
        </div>
        <div className="col-md-4 service-item">
          <FontAwesomeIcon icon={faShoppingCart} size="3x" className="fa-icon custom-icon-color" />
          <h4>Venda de Equipamentos/Produtos</h4>
          <p>Temos todos os equipamentos e produtos que necessita para manter a sua piscina em dia.</p>
        </div>
        <div className="col-md-4 service-item">
          <FontAwesomeIcon icon={faWrench} size="3x" className="fa-icon custom-icon-color" />
          <h4>Assistência Técnica</h4>
          <p>Temos um serviço de assistência técnica.</p>
        </div>
        <div className="col-md-4 service-item">
          <FontAwesomeIcon icon={faSwimmer} size="3x" className="fa-icon custom-icon-color" />
          <h4>Construção de Piscinas</h4>
          <p>Oferecemos serviços de construção de piscinas com alta qualidade e durabilidade.</p>
        </div>
        <div className="col-md-4 service-item">
          <FontAwesomeIcon icon={faDraftingCompass} size="3x" className="fa-icon custom-icon-color" />
          <h4>Projetos Arquitetura Paisagista</h4>
          <p>Desenvolvemos projetos de arquitetura paisagista para transformar o seu espaço.</p>
        </div>
        <div className="col-md-4 service-item">
          <FontAwesomeIcon icon={faTree} size="3x" className="fa-icon custom-icon-color" />
          <h4>Espaços Verdes</h4>
          <p>Criamos e mantemos espaços verdes para embelezar o seu ambiente.</p>
        </div>
      </div>
    </div>
  );
}

export default ServiceOptions;
