import React, { useState, useEffect } from "react";
import "./Header.css"; // Import the custom CSS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import logoWhite from "../assets/Home/logo_branco_s_fundo.webp"; // Import the white logo
import logoBlue from "../assets/Home/logo_azul_s_fundo.webp"; // Import the blue logo

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`header ${isScrolled ? "scrolled" : ""}`}>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={logoWhite} alt="Logo" className={`logo logo-white ${isScrolled ? "d-none" : "d-block"}`} />
            <img src={logoBlue} alt="Logo" className={`logo logo-blue ${isScrolled ? "d-block" : "d-none"}`} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            aria-controls="navbarNav"
            aria-expanded={isOpen}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`} id="navbarNav">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/">
                  Página Inicial
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/sobre">
                  Sobre
                </a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#!" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Piscinas
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="/Portfolio">
                      Portfólio
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/Antes-e-Depois">
                      Antes e Depois
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Servicos">
                  Serviços
                </a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#!" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Produtos
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="/Coberturas">
                      Coberturas
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/Aspiradores">
                      Aspiradores
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/Equipamento-produtos">
                      Equipamento e produtos
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Espaços-Verdes">
                  Espaços Verdes
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Contacto">
                  Contacto
                </a>
              </li>
            </ul>
            <div className="d-flex">
              <a href="https://www.facebook.com/hidrolazer.piscinas.1" target="_blank" className="social-icon nav-link">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="https://www.instagram.com/hidrolazer_/" target="_blank" className="social-icon nav-link">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="mailto:geral@hidro-lazer.com" className="social-icon nav-link">
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
